<template>
    <!--查询组内所有员工,不根据权限限定,权限无关-->
    <el-select ref="selectStaff" v-bind="$attrs" v-on="$listeners" filterable clearable>
        <el-option value="" label="全部"></el-option>
        <el-option v-for="staff in meta.staffs" :value="staff.code" :key="staff.code" :label="staff.realName" />
    </el-select>
</template>

<script>
export default {
    name: 'EfnAllStaffInGroup',
    props: {
        groupCode: {
            type: String,
            default: '',
        },

        defaultSelected: {
            type: Boolean,
            default: false,
        },
        // 外面的机构组是否加载完成
        flag: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            staffCode: '',
            selectData: [],
            meta: {
                staffs: [],
            },
            groupPeople: '/system/staff/queryExtend',
        };
    },
    mounted() {
        this.queryStaff();
    },
    watch: {
        groupCode() {
            this.queryStaff();
        },
    },
    methods: {
        async queryStaff() {
            // 等外层的机构组加载好 才查询创建人
            if (this.flag) {
                this.loadingStart();
                const rst = await this.$http.get(this.groupPeople + '?deptGroupCode=' + this.groupCode);
                this.meta.staffs = rst['data'].data;
                if (this.defaultSelected) {
                    if (this.meta.staffs.length) {
                        const _sessionStaffCode = this.$store.state.session.code;
                        const _staff = this.meta.staffs.find((s) => s.code === _sessionStaffCode);
                        if (_staff) {
                            this.value = _sessionStaffCode;
                        } else {
                            this.value = this.meta.staffs[0].code;
                        }
                    }
                }
                this.loadingStop();
            }
        },
    },
};
</script>
