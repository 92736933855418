<template>
    <div class="promotion" style="background-color: #f5f7ff">
        <el-card shadow="never" style="margin-top: 8px; padding-bottom: 0">
            <el-form inline class="pform" size="medium" :model="form">
                <el-form-item label="创建日期">
                    <efn-date-range v-model="meta.createTime"></efn-date-range>
                </el-form-item>
                <el-form-item label="促销活动日期" label-width="120px">
                    <efn-date-range v-model="meta.launchTime"></efn-date-range>
                </el-form-item>
                <el-form-item style="float: right">
                    <el-autocomplete
                        v-model="form.name"
                        placeholder="可搜索活动名称"
                        @keyup.enter.native="onFindPageList"
                        clearable
                        :fetch-suggestions="onQuerySearch"
                        @select="onPickData"
                        @change="onSearchChange"
                        :trigger-on-focus="false"
                    >
                        <i class="el-icon-search el-input__icon" slot="suffix" @click="onFindPageList"> </i>
                    </el-autocomplete>
                    <el-button type="primary" style="margin-left: 10px; width: 98px" @click="searchHandleQuery">
                        搜索
                    </el-button>
                </el-form-item>

                <br />
                <el-form-item label="促销类型">
                    <el-select v-model="form.promotionType" ref="selectStaff" filterable clearable>
                        <el-option value="" key="" label="全部"></el-option>
                        <el-option value="4" key="4" label="单品件数折扣"></el-option>
                        <el-option value="5" key="5" label="任选一口价"></el-option>
                        <el-option value="6" key="6" label="单品满赠"></el-option>
                        <el-option value="7" key="7" label="固定一口价"></el-option>
                        <el-option value="8" key="8" label="任选件数折扣"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="机构组" label-width="100px">
                    <el-select
                        v-model="form.deptGroupCode"
                        @change="handlerChangeDeptGroup"
                        filterable
                        style="width: 180px"
                    >
                        <el-option value="" label="全部" v-if="meta.groups && meta.groups.length > 0" />
                        <el-option
                            v-for="group in meta.groups"
                            :value="group.code"
                            :key="group.code"
                            :label="group.name"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item label="创建人" label-width="100px">
                    <efn-all-staff-in-group
                        :group-code="form.deptGroupCode"
                        :flag="deptGroupLoadFlag"
                        v-model="form.creator"
                        @change="changeCreator"
                    ></efn-all-staff-in-group>
                </el-form-item>
                <el-form-item style="float: right">
                    <el-button type="primary" @click="create" v-if="hasPrivilege('menu.promotion.npromotion.create')"
                        >新建促销</el-button
                    >
                </el-form-item>
                <br />
                <el-form-item label="活动状态">
                    <el-radio-group v-model="form.enableFlag" @change="onFindPageList">
                        <el-radio-button label="">全部</el-radio-button>
                        <el-radio-button :label="true">启用</el-radio-button>
                        <el-radio-button :label="false">停用</el-radio-button>
                    </el-radio-group>
                </el-form-item>
                <!--  todo://暂未实现打印导出等功能              <el-form-item style="float: right"><efn-button-more></efn-button-more></el-form-item>-->
            </el-form>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <div
                class="clearfix"
                style="
                    margin: 16px auto;
                    overflow: hidden;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                "
            >
                <div>促销方案列表</div>
                <div>
                    <el-button
                        type="primary"
                        plain
                        size="medium"
                        @click="onBatchEnable"
                        v-if="hasPrivilege('menu.promotion.npromotion.edit')"
                        >批量启用</el-button
                    >
                    <el-button
                        type="primary"
                        plain
                        size="medium"
                        @click="onBatchDisable"
                        v-if="hasPrivilege('menu.promotion.npromotion.edit')"
                        >批量停用</el-button
                    >
                </div>
            </div>
            <div>
                <!--                <el-scrollbar>-->
                <el-table
                    :data="tableData"
                    stripe
                    border
                    style="overflow: hidden"
                    highlight-current-row
                    max-height="488"
                    ref="table"
                    :row-style="{ height: '20' }"
                    :cell-style="{ padding: '0' }"
                    @row-dblclick="
                        (row) => {
                            this.$refs.table.toggleRowSelection(row);
                        }
                    "
                >
                    <el-table-column type="selection"></el-table-column>
                    <el-table-column type="index" label="NO."></el-table-column>
                    <el-table-column label="促销活动名称" prop="name" min-width="120"></el-table-column>
                    <el-table-column label="促销类型" width="120" prop="type">
                        <template slot-scope="scope">
                            <span>{{ scope.row.rule | rule }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="促销状态" width="100" prop="enableFlag" key="enableFlag">
                        <template slot-scope="scope">
                            <span v-if="scope.row.enableFlag" class="clr-blue">启用</span>
                            <span v-if="!scope.row.enableFlag">停用</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="促销活动时间" prop="launchDateScope" width="285px">
                        <template slot-scope="scope">
                            {{ scope.row.startDate }} -
                            {{ scope.row.endDate }}
                        </template>
                    </el-table-column>
                    <el-table-column label="创建人" prop="creatorName" width="110"></el-table-column>
                    <el-table-column label="机构组" prop="groupName" min-width="120"></el-table-column>
                    <el-table-column label="创建时间" prop="createTime" width="210"></el-table-column>

                    <el-table-column label="其他操作" fixed="right" width="182px" align="center">
                        <template slot-scope="scope">
                            <el-button
                                type="text"
                                @click="copy(scope.row)"
                                v-if="hasPrivilege('menu.promotion.npromotion.edit')"
                                >复制</el-button
                            >
                            <el-button
                                type="text"
                                @click="enableOrDisable(scope.row, true)"
                                v-if="!scope.row.enableFlag && hasPrivilege('menu.promotion.npromotion.edit')"
                                >启用
                            </el-button>
                            <el-button
                                type="text"
                                @click="enableOrDisable(scope.row, false)"
                                v-if="scope.row.enableFlag && hasPrivilege('menu.promotion.npromotion.edit')"
                                >停用
                            </el-button>
                            <el-button type="text" @click="onLineLaunch(scope.row)">投放</el-button>
                            <el-button
                                type="text"
                                style="color: #f00"
                                @click="inValid(scope.row)"
                                v-if="hasPrivilege('menu.promotion.npromotion.delete')"
                                >作废</el-button
                            >
                        </template>
                    </el-table-column>
                    <el-table-column label=" 操作" fixed="right" width="180" align="center">
                        <template slot-scope="scope">
                            <el-button
                                type="text"
                                @click="onLineDetail(scope.row.promotionCode)"
                                v-if="hasPrivilege('menu.promotion.npromotion.open')"
                                >查看</el-button
                            >
                            <el-button
                                type="text"
                                @click="onLineEdit(scope.row)"
                                v-if="hasPrivilege('menu.promotion.npromotion.edit')"
                                :disabled="!scope.row['canEditFlag'] || scope.row.enableFlag"
                                >编辑
                            </el-button>
                            <el-button
                                type="text"
                                @click="operationRecord(scope.row)"
                                v-if="hasPrivilege('menu.promotion.npromotion.open')"
                                >操作记录</el-button
                            >
                        </template>
                    </el-table-column>
                </el-table>
                <ef-pagination
                    @pageChange="pageChange"
                    :total="page.total"
                    :default-limit="form.limit"
                    :current-page="form.page"
                ></ef-pagination>
            </div>
        </el-card>
        <!--   操作记录弹窗   -->
        <el-dialog title="操作记录" :visible.sync="dialogTableVisible">
            <el-table :data="gridData" height="450" border style="width: 100%">
                <el-table-column label="序号" type="index" width="50"> </el-table-column>
                <el-table-column prop="name" label="促销活动名称" width="180"> </el-table-column>
                <el-table-column label="促销活动时间" width="180">
                    <template slot-scope="scope">
                        {{ scope.row.startDate }} -
                        {{ scope.row.endDate }}
                    </template>
                </el-table-column>
                <el-table-column prop="enableFlag" label="促销状态" width="180">
                    <template slot-scope="scope">
                        <span v-if="scope.row.enableFlag == true">启用</span>
                        <span v-if="scope.row.enableFlag == false">停用</span>
                        <span v-if="scope.row.enableFlag == null">无</span>
                    </template>
                </el-table-column>
                <el-table-column prop="editRuleFlag" label="是否调整活动规则" width="180">
                    <template slot-scope="scope">
                        <span v-if="scope.row.editRuleFlag == true">是</span>
                        <span v-if="scope.row.editRuleFlag == false">否</span>
                        <span v-if="scope.row.editRuleFlag == null">无</span>
                    </template>
                </el-table-column>
                <el-table-column prop="editLaunchFlag" label="是否调整投放规则" width="180">
                    <template slot-scope="scope">
                        <span v-if="scope.row.editLaunchFlag == true">是</span>
                        <span v-if="scope.row.editLaunchFlag == false">否</span>
                        <span v-if="scope.row.editLaunchFlag == null">无</span>
                    </template>
                </el-table-column>
                <el-table-column prop="editLaunchDeptFlag" label="是否调整投放门店" width="180">
                    <template slot-scope="scope">
                        <span v-if="scope.row.editLaunchDeptFlag == true">是</span>
                        <span v-if="scope.row.editLaunchDeptFlag == false">否</span>
                        <span v-if="scope.row.editLaunchDeptFlag == null">无</span>
                    </template>
                </el-table-column>
                <el-table-column prop="operator" label="操作人" width="180"> </el-table-column>
                <el-table-column prop="createTime" label="操作时间" width="180"> </el-table-column>
                <el-table-column prop="operationWay" label="操作途径" width="180"> </el-table-column>
            </el-table>
            <ef-pagination
                @pageChange="gridDatapageChange"
                :total="page.gridDataTotal"
                :default-limit="grid.limit"
                :current-page="grid.page"
            ></ef-pagination>
        </el-dialog>
    </div>
</template>
<script>
import EfPagination from 'components/EfPagination';
import EfnDateRange from 'components/newStyle/EfnDateRange';
import EfnAllStaffInGroup from 'components/newStyle/EfnAllStaffInGroup';
import UrlUtils from 'js/UrlUtils';

export default {
    name: 'Promotion',
    components: { EfnAllStaffInGroup, EfnDateRange, EfPagination },
    data() {
        return {
            form: {
                startTime: '',
                endTime: '',
                promotionStartTime: '',
                promotionEndTime: '',
                name: '',
                enableFlag: '',
                deptGroupCode: '',
                promotionType: '',
                creator: '',
                page: 1,
                limit: 20,
            },
            page: {
                total: 0,
                gridDataTotal: 0,
            },
            repeatPromotion: [],
            meta: {
                groups: [],
                createTime: ['', ''],
                launchTime: ['', ''],
            },
            tableData: [],
            url: {
                copy: '/npromotion/copy',
                page: '/npromotion/page',
                enableOrDisable: '/npromotion/enableOrDisable',
                inValid: '/npromotion/inValid',
                queryGroups: '/system/deptGroup/staffManagedAllDeptGroups',
                batchEnableOrDisable: '/npromotion/batchEnableOrDisable',
            },
            gridData: [],
            dialogTableVisible: false,
            grid: {
                page: 1,
                limit: 10,
            },
            row: null,
            deptGroupLoadFlag: false,
        };
    },
    mounted() {
        UrlUtils.DeptGroup(this, (rst) => {
            this.meta.groups = rst || [];
            this.form.deptGroupCode = this.meta.groups[0].code;
            this.onFindPageList();
            this.deptGroupLoadFlag = true;
        });
    },
    activated() {
        this.executeQueryIfNeedReload(this.onFindPageList);
    },
    watch: {
        meta: {
            handler: function (val) {
                if (val['createTime']) {
                    this.form.startTime = val.createTime[0];
                    this.form.endTime = val.createTime[1];
                } else {
                    this.form.startTime = '';
                    this.form.endTime = '';
                }
                if (val['launchTime']) {
                    this.form.promotionStartTime = val.launchTime[0];
                    this.form.promotionEndTime = val.launchTime[1];
                } else {
                    this.form.promotionStartTime = '';
                    this.form.promotionEndTime = '';
                }
            },
            deep: true,
        },
    },
    methods: {
        //操作记录
        operationRecord(row) {
            this.loadingStart();
            this.gridData = null;
            this.row = row;
            this.$http
                .get('/npromotion/detail/operationLog/' + row.promotionCode, { params: this.grid })
                .then((rst) => {
                    this.gridData = rst.data.data;
                    this.page.gridDataTotal = rst.data.count;
                    if (rst.data.status == 200) {
                        this.dialogTableVisible = true;
                    }
                    this.loadingStop();
                });
        },
        handlerChangeDeptGroup() {
            // 选择机构组后 清空创建人
            this.form.creator = '';
        },
        pageChange(page, limit) {
            this.form.page = page;
            this.form.limit = limit;
            this.onFindPageList();
        },
        gridDatapageChange(page, limit) {
            this.loadingStart();
            this.grid.page = page;
            this.grid.limit = limit;
            this.$http
                .get('/npromotion/detail/operationLog/' + this.row.promotionCode, { params: this.grid })
                .then((rst) => {
                    this.gridData = rst.data.data;
                    this.page.gridDataTotal = rst.data.count;
                    this.loadingStop();
                });
        },
        changeCreator(creator) {
            this.form.creator = creator;
        },

        copy(row) {
            this.loadingStart();
            this.$http.get(this.url.copy, { params: { code: row.promotionCode } }).then((rst) => {
                if (rst.data.status === 200) {
                    this.$alert('复制成功', '提示', {
                        confirmButtonText: '确定',
                        type: 'success',
                        callback: () => {
                            this.onFindPageList();
                            this.loadingStop();
                        },
                    });
                } else {
                    this.$message.error(rst.data.message);
                }
            });
        },
        create() {
            this.$router.push({ name: 'menu.promotion.npromotion.create.type' });
        },
        searchHandleQuery() {
            this.form.page = 1;
            this.onFindPageList();
        },
        async onFindPageList() {
            this.loadingStart();
            const _this = this;
            const rst = await this.$http.get(_this.url.page, { params: _this.form });
            this.loadingStop();
            if (rst['status'] === 200 && rst['data'].status === 200) {
                this.tableData = rst['data'].data;
                this.dataBackUp = this.tableData;
                this.page.total = rst['data'].count;
            }
        },
        onQuerySearch(queryString, cb) {
            const results = queryString
                ? this.dataBackUp
                      .map((d) => {
                          return { value: d.name };
                      })
                      .filter((d) => d.value.includes(queryString))
                : [];
            // 调用 callback 返回建议列表的数据
            cb(results);
        },
        onBatchEnable() {
            const _this = this;
            const codes = this.$refs.table.selection.map((c) => c.promotionCode);
            if (!codes.length) {
                return;
            }
            this.loadingStart();
            this.$http.post(_this.url.batchEnableOrDisable, { codes: codes, enable: true }).then((rst) => {
                this.loadingStop();
                if (rst['status'] === 200 && rst['data'].status === 200) {
                    _this.$message.success('操作成功');
                    _this.onFindPageList();
                }
            });
        },
        onBatchDisable() {
            const _this = this;
            const codes = this.$refs.table.selection.map((c) => c.promotionCode);
            if (!codes.length) {
                return;
            }
            this.loadingStart();
            this.$http.post(_this.url.batchEnableOrDisable, { codes: codes, enable: false }).then((rst) => {
                this.loadingStop();
                if (rst['status'] === 200 && rst['data'].status === 200) {
                    _this.$message.success('操作成功');
                    _this.onFindPageList();
                }
            });
        },
        onSearchChange(queryString) {
            if (!queryString) {
                this.tableData = this.dataBackUp;
            }
        },
        onPickData(data) {
            this.tableData = this.dataBackUp.filter((d) => d.name === data.value);
            this.page.total = 1;
        },
        inValid(row) {
            if (row.enableFlag) {
                this.$message.error('未停用的活动不能作废');
                return;
            }
            const code = row.promotionCode;
            const _this = this;

            UrlUtils.PatchRemote(this, _this.url.inValid, { code: code }, null, () => {
                this.onFindPageList();
                this.$confirm('操作成功');
            });
        },
        onLineDetail(promotionCode) {
            this.$router.push({
                name: 'menu.promotion.npromotion.detail',
                params: { editPromotionCode: promotionCode },
            });
        },
        onLineEdit(row) {
            if (row.enableFlag) {
                this.$message.error('启用中的活动不能编辑');
                return;
            }
            if (!row['canEditFlag']) {
                this.$message.error('已生效活动不能编辑');
                return;
            }
            if (row.rule === 4) {
                this.$router.push({
                    name: 'menu.promotion.npromotion.create.specialOffer',
                    params: { editPromotionCode: row.promotionCode },
                });
            } else if (row.rule === 5) {
                this.$router.push({
                    name: 'menu.promotion.npromotion.create.buyoutPrice',
                    params: { editPromotionCode: row.promotionCode },
                });
            } else if (row.rule === 6) {
                this.$router.push({
                    name: 'menu.promotion.npromotion.create.giveaway',
                    params: { editPromotionCode: row.promotionCode },
                });
            } else if (row.rule === 7) {
                this.$router.push({
                    name: 'menu.promotion.npromotion.create.fixBuyOut',
                    params: { editPromotionCode: row.promotionCode },
                });
            } else if (row.rule === 8) {
                this.$router.push({
                    name: 'menu.promotion.npromotion.create.fixDiscount',
                    params: { editPromotionCode: row.promotionCode },
                });
            }
        },
        onLineLaunch(row) {
            this.$router.push({
                name: 'menu.promotion.npromotion.create.launch',
                params: { promotionCode: row.promotionCode },
            });
        },
        enableOrDisable(row, enableFlag) {
            if (enableFlag === row.enableFlag) {
                return;
            }
            this.$http
                .get(this.url.enableOrDisable, { params: { code: row.promotionCode, enableFlag: enableFlag } })
                .then((rst) => {
                    if (rst.data.status === 200) {
                        for (let i = 0; i < rst.data.data.length; i++) {
                            this.repeatPromotion.push(rst.data.data[i].name);
                        }
                        this.$alert(
                            rst.data.data.length === 0
                                ? '操作成功'
                                : '操作成功' + '有部分商品参加重复的活动:' + this.repeatPromotion,
                            '提示',
                            {
                                confirmButtonText: '确定',
                                type: 'success',
                                callback: () => {
                                    this.onFindPageList();
                                },
                            }
                        );
                    } else {
                        this.$message.error(rst.data.message);
                    }
                });
        },
    },
    filters: {
        rule(rule) {
            if (typeof rule == 'undefined') {
                return '';
            }
            if (rule === 4) {
                return '单品件数折扣';
            }
            if (rule === 5) {
                return '任选一口价';
            }
            if (rule === 6) {
                return '单品满赠';
            }
            if (rule === 7) {
                return '固定一口价';
            }
            if (rule === 8) {
                return '任选件数折扣';
            }
        },
    },
};
</script>
